import React, { useEffect, useState } from 'react';
import { Spinner } from '../common';
import { Select, Button, Grid, GridCell, Heading, Icon, Main, Switch, Text, TextLink } from '@blueprints2/core';
import HomeAPI from '../../actions/home';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../store';
import './Home.css';
import {CMP_VALUE, MerchantRecordType, OrderTrackingField, StepStatus} from '../../constants/constants';
import { CCPAStatus, Merchant, MerchantMid, OrderCancellationRequest, ProductFeedRequest } from '../../constants/interfaces';
import OnOffSwitcher from './OnOffSwitcher';
import CustomPopover from '../utils';
import OrderTrackingFieldConfirmation from './OrderTrackingFieldConfirmation';

interface HomeProps {
	onHttpStatusChange?: Function;
}

const Home = (props: HomeProps) => {
	const { onHttpStatusChange } = props;
	const { statuses } = useSelector((state: Store) => state.home);
	const { merchant } = useSelector((state: Store) => state.merchant);
	// @ts-ignore
	const [merchantState, setMerchantState] = useState({ merchant: undefined as Merchant });
	const [productFeedSwitchState, setProductFeedSwitchState] = useState({ value: false });
	const [ordersCancellationSwitchState, setOrdersCancellationSwitchState] = useState({ value: false });
	const dispatch = useDispatch();

	const [orderTrackingFieldState, setOrderTrackingFieldState] = useState({ value: undefined, showConfirmation: false, errorMessage: '' });
	const [popoversState, setPopoversState] = useState({ autoCancellationShowPopover: false, complianceShowPopover: false, productFeedShowPopover: false, orderTrackingFieldShowPopover: false});

	const [formState, setFormState] = useState({
		ccpaStatus: { status: StepStatus.Incomplete, type: '', withGuide: false } as CCPAStatus,
		spiStatus: StepStatus.Completed,
		additionalScriptStatus: StepStatus.Incomplete,
		pixelTrackingStatus: StepStatus.Incomplete,
		productFeedSyncStatus: StepStatus.Completed,
		isStatusLoaded: false,
		thirdPartyAppStatus: StepStatus.Incomplete,
		orderCancellationStatus: StepStatus.Completed,
		isLoading: false,
		webPixelEnabled: false,
		serverPixelEnabled: false,
		orderTrackingField: undefined,
		networkId: 0,
	});

	const errorCallback = (status: number) => {
		setFormState({ ...formState, isStatusLoaded: true, isLoading: false });
		if (statuses) {
			setProductFeedSwitchState({ value: statuses.productFeedSyncStatus === StepStatus.Completed });
			setOrdersCancellationSwitchState({ value: statuses.orderCancellationStatus === StepStatus.Completed });
		}
		if (onHttpStatusChange) {
			onHttpStatusChange(status);
		}
	};

	const getStatuses = async (mid: string) => {
		await dispatch(HomeAPI.getStatuses(mid, errorCallback));
	};

	const loadingCallback = (isLoading: boolean) => {
		setFormState({ ...formState, isLoading: isLoading });
	};

	const updateProductFeeds = async (productFeedRequest: ProductFeedRequest) => {
		await dispatch(HomeAPI.updateProductFeed(productFeedRequest, loadingCallback, errorCallback));
	};

	const getProductFeedFile = async () => {
		setFormState({ ...formState, isLoading: true });
		await dispatch(HomeAPI.getProductFeedFile(merchantState.merchant.mid, loadingCallback));
	};

	const updateOrderCancellation = async (orderCancellationRequest: OrderCancellationRequest) => {
		await dispatch(HomeAPI.updateOrderCancellation(orderCancellationRequest, loadingCallback, errorCallback));
	};

	const getOrderTrackingFieldOptions = () => {
		return (Object.keys(OrderTrackingField) as Array<keyof typeof OrderTrackingField>).map((key) => {
			return <option className={'option-custom'} value={key}>{OrderTrackingField[key]}</option>;
		});
	};

	const getGuideLink = (cmpType: string) => {
		if (cmpType === CMP_VALUE.iabFramework || cmpType === CMP_VALUE.cookie) {
			return (
				<Text style={{ fontSize: '12px', marginTop: '-1rem' }} className='text-purple-500'>
					<a rel='noopener noreferrer' href='https://rak.app.box.com/s/jbj68qegtumzejee9f9cju2u0a1iwpai' target='_blank'>
						View implementation guide
					</a>
				</Text>
			);
		}
		if (cmpType === 'privacyPolicy') {
			return (
				<Text style={{ fontSize: '12px', marginTop: '-1rem' }} className='text-purple-500'>
					<a rel='noopener noreferrer' href='https://rak.app.box.com/s/r5vh311l1yfcdetbkm1zu6qlf8bqwsb8' target='_blank'>
						View implementation guide
					</a>
				</Text>
			);
		}
		return null;
	};

	const onProductFeedChange = () => {
		const value = !(formState.productFeedSyncStatus === StepStatus.Completed);
		loadingCallback(true);
		const productFeedRequest = {
			productFeedEnabled: !(formState.productFeedSyncStatus === StepStatus.Completed),
			mid: merchantState.merchant.mid,
			recordType: merchantState.merchant.mids.filter((m) => m.mid === merchantState.merchant.mid)[0].recordType,
			imported: merchantState.merchant.imported,
		} as ProductFeedRequest;
		setProductFeedSwitchState({ value: value });
		updateProductFeeds(productFeedRequest);
	};

	const onOrderCancellationChange = () => {
		const value = !(formState.orderCancellationStatus === StepStatus.Completed);
		loadingCallback(true);
		const orderCancellationRequest = {
			orderCancellationEnabled: !(formState.orderCancellationStatus === StepStatus.Completed),
			mid: merchantState.merchant.mid,
			recordType: merchantState.merchant.mids.filter((m) => m.mid === merchantState.merchant.mid)[0].recordType,
			imported: merchantState.merchant.imported,
		} as OrderCancellationRequest;
		setOrdersCancellationSwitchState({ value: value });
		updateOrderCancellation(orderCancellationRequest);
	};

	const getIcon = (status: StepStatus) => {
		switch (status) {
			case StepStatus.Incomplete:
				return (
					<svg height='55' width='90'>
						<circle cx='45' cy='30' stroke='black' strokeWidth='1' r='12' fill='white' />
					</svg>
				);
			case StepStatus.Completed:
				return (
					<>
						<Icon type='checkmark-solid' className='w-6 h-8 text-green-500 mt-4' />
						<Text className='text-green-500 status-text'>Complete</Text>
					</>
				);
			case StepStatus.InReview:
				return (
					<>
						<svg height='50' width='90'>
							<circle cx='45' cy='35' r='12' fill='#ffa002' />
						</svg>
						<Text className='text-orange-500 status-text'>In review</Text>
					</>
				);
			case StepStatus.Failed:
				return (
					<>
						<svg height='50' width='90'>
							<circle cx='45' cy='35' r='12' fill='#d73955' />
						</svg>
						<Text className='text-red-500 status-text'>Failed</Text>
					</>
				);
		}
	};

	const extractThirdPartyAppButton = (): unknown => {
		let pixelButton: unknown;
		if (formState.thirdPartyAppStatus.valueOf() === StepStatus.Incomplete.valueOf()) {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/checkout-apps'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		} else {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/checkout-apps'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		}
		return pixelButton;
	};

	const extractCCPAButton = (): unknown => {
		let ccpaButton: unknown;
		if (formState.ccpaStatus.status.valueOf() === StepStatus.Completed.valueOf()) {
			ccpaButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/ccpa'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		} else if (formState.ccpaStatus.status.valueOf() === StepStatus.InReview.valueOf()) {
			ccpaButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/ccpa'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Edit
						</Button>
					</TextLink>
				</span>
			);
		} else {
			ccpaButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/ccpa'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		}
		return ccpaButton;
	};

	const extractPixelTrackingButton = (): unknown => {
		let pixelButton: unknown = '';
		if (
			formState.pixelTrackingStatus.valueOf() === StepStatus.Incomplete.valueOf() ||
			formState.pixelTrackingStatus.valueOf() === StepStatus.Failed.valueOf()
		) {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/pixel'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		} else {
			pixelButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/pixel'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		}
		return pixelButton;
	};

	const getAutoCancellationPopoverText = () => {
		return (
			<p>
				If you select to enable automatic cancellations, we will use the Shopify Refund web hook to listen for cancellations. When an order
				is cancelled, we check to see which line items in the order were cancelled and then report those back to Rakuten Advertising as
				cancellations using our real-time transaction reporting API.
			</p>
		);
	};

	const getProductFeedPopoverText = () => {
		return (
			<span>
				<p>
					If you choose to enable automatic product feed creation, we will use the Shopify Product API to create a product feed that meets
					Rakuten Advertising’s standards. Your feed will be uploaded to Rakuten Advertising once a day. Please note that the following
					fields are required, and any products that don’t have these values will not be processed: Title, Description, Image and Price.
				</p>
				<p>
					To see an example of the product feed we can create for you based on the Shopify Product API, click the "Download product feed sample"
					link here.
				</p>
			</span>
		);
	};

	const getOrderTrackingFieldPopoverText = () => {
		return (
			<span className={'text-xs'}>
				<p>
					Shopify offers multiple types of identifiers for transactions/orders. You can select the preferred identifier for Rakuten to use in tracking and identifying orders within the affiliate system. The chosen identifier will be utilized in reporting to publishers and for handling transaction inquiries.
				</p>
				<p>
					<b>Order ID:</b><br/>
					<ul style={{listStyle: 'disc'}}>
						<li><b>Definition:</b> The internal order ID used by Shopify, which consists of a 13-digit numeric sequence.</li>
						<li><b>Example:</b> 5412369874123</li>
						<li><b>Visibility:</b> This ID is primarily for internal use and is not visible to customers. It can be found in the URL when accessing order details in the Shopify admin panel.</li>
						<li><b>Use Case:</b> Ideal for merchants who prefer a straightforward, numeric tracking system that aligns with internal records.</li>
					</ul>
				</p>
				<p>
					<b>Order Name:</b><br/>
					<ul style={{listStyle: 'disc'}}>
						<li><b>Definition:</b> The customer-facing identifier seen in confirmation emails, which may include alphanumeric characters depending on any custom prefixes or suffixes you have set.</li>
						<li><b>Example:</b> #US1035</li>
						<li><b>Visibility:</b> Appears under the "Order" column in the "Orders" overview screen within the Shopify admin.</li>
						<li><b>Use Case:</b> Best for those who want an identifier that is easily recognizable by customers and can be customized.</li>
					</ul>
				</p>
				<p>
					<Icon type={'information-outline'} className={'text-orange-500'} /><b> Impact of Switching Order ID Types:</b><br/>
					Switching between order ID types will only affect new orders moving forward; historical orders will retain their original identifiers. To maintain consistency and avoid confusion among your publishers, frequent switching between types is not recommended.
				</p>
			</span>
		);
	};

	const extractAdditionalButton = (): unknown => {
		let additionalButton;
		if (formState.additionalScriptStatus.valueOf() === StepStatus.Incomplete.valueOf()) {
			additionalButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/tracking'>
						<Button isDisabled={formState.isLoading} isSecondary>
							Start
						</Button>
					</TextLink>
				</span>
			);
		} else if (
			formState.additionalScriptStatus.valueOf ===
			(StepStatus.InReview.valueOf || StepStatus.Failed.valueOf || StepStatus.Completed.valueOf)
		) {
			additionalButton = (
				<span className={formState.isLoading ? 'link-disabled' : ''}>
					<TextLink to='/tracking'>
						<Button isDisabled={formState.isLoading} isSecondary>
							View
						</Button>
					</TextLink>
				</span>
			);
		}
		return additionalButton;
	};

	const closeOrderTrackingFieldConfirmation = () => {
		if (orderTrackingFieldState.errorMessage) {
			setFormState({ ...formState, isLoading: true, isStatusLoaded: false });
			setOrderTrackingFieldState({ ...orderTrackingFieldState, showConfirmation: false, errorMessage: '' });
			dispatch(HomeAPI.clearState());
			dispatch(HomeAPI.getStatuses(merchantState.merchant.mid, errorCallback));
		} else {
			setOrderTrackingFieldState({ ...orderTrackingFieldState, showConfirmation: false});
		}

	};

	const updateOrderTrackingFieldCallBack = async () => {
		setFormState({ ...formState, isStatusLoaded: false, isLoading: true });
		setOrderTrackingFieldState({ ...orderTrackingFieldState, showConfirmation: false });
		dispatch(HomeAPI.clearState());
		dispatch(HomeAPI.getStatuses(merchantState.merchant.mid, errorCallback));
	};

	const errorOrderTrackingFieldCallback = (message: string) => {
		setOrderTrackingFieldState({ ...orderTrackingFieldState, errorMessage: message });
	};

	const updateOrderTrackingField = async (loadingCallback: Function) => {
		setOrderTrackingFieldState({ ...orderTrackingFieldState, errorMessage: '' });
		dispatch(
			HomeAPI.updateOrderTrackingField(
				{orderTrackingField: formState.orderTrackingField},
				updateOrderTrackingFieldCallBack,
				errorOrderTrackingFieldCallback,
				loadingCallback
			)
		);
	};

	const getProductFeedMainText = () => {
		return (
			<span>
				Product feed creation and daily upload
				<CustomPopover
					show={popoversState.productFeedShowPopover}
					className={'popover'}
					position={'right'}
					onShow={() => {
						setPopoversState({ ...popoversState, productFeedShowPopover: true });
					}}
					onDismiss={() => {
						setPopoversState({ ...popoversState, productFeedShowPopover: false });
					}}
					iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
					iconType={'information-solid'}
					actionsClassName={'popover-actions-grey'}
				>
					{getProductFeedPopoverText()}
				</CustomPopover>
			</span>
		);
	};

	const handleOrderTrackingFieldChange = (event: any) => {

		if (event && event.value) {
			setFormState({...formState, orderTrackingField: event.value});
		}
	};

	const renderOrderTrackingFieldSection = () => {
		return (
			<span>
				<hr />
				<Grid>
					<GridCell key={2} width='3/4' className='mt-4 px-2'>
						<span style={{display: 'flex'}}>
							<span style={{display: 'flex'}}>
								<Text variation='strong'>Order ID Type</Text>
								<CustomPopover
									show={popoversState.orderTrackingFieldShowPopover}
									className={'popover-715'}
									position={'right'}
									onShow={() => {
										setPopoversState({...popoversState, orderTrackingFieldShowPopover: true});
									}}
									onDismiss={() => {
										setPopoversState({...popoversState, orderTrackingFieldShowPopover: false});
									}}
									iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip order-tracking-field-info'}
									iconType={'information-solid'}
									actionsClassName={'popover-actions-grey'}
								>
									{getOrderTrackingFieldPopoverText()}
								</CustomPopover>
							</span>
							<Select
								name={'orderTrackingField'}
								isDisabled={formState.isLoading}
								fixedWidth={150}
								className={'tracking-field-select-drawer'}
								onChange={handleOrderTrackingFieldChange}
								isSmall
								placeholder={''}
								value={formState && formState.orderTrackingField ? formState.orderTrackingField : ''}
							>
								{getOrderTrackingFieldOptions()}
							</Select>
						</span>
						<Text className={'text-xs'}>
							Select which Shopify order ID type you want Rakuten to use for transaction tracking.
							Click on the info icon for more information and possible implications.
						</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						<Button
							isDisabled={formState.isLoading || formState.orderTrackingField === orderTrackingFieldState.value}
							isSecondary
							onClick={() => {
								setOrderTrackingFieldState({...orderTrackingFieldState, showConfirmation: true});
							}}
						>
							Update
						</Button>
					</GridCell>
				</Grid>
				<hr/>
			</span>
		);
	};


	const renderAutomaticallyCompletedElements = (): JSX.Element => {
		return (
			<Main className='px-10 pt-4' style={{minHeight: '10rem'}}>
				<Heading level={3}>To get the app installation started, we’ve automatically completed these first steps for you</Heading>
				<hr style={{marginTop: '7px'}}/>
				<Grid>
					<GridCell className='text-center m-auto px-2' key={1} width='1/6'>
						{getIcon(formState.spiStatus)}
					</GridCell>
					<GridCell key={2} width='5/6' className='mt-5 px-2'>
						<Text variation='strong'>Tracking tags install</Text>
						<Text style={{fontSize: '12px', marginTop: '-1rem'}}>Basic tracking for your store</Text>
					</GridCell>
				</Grid>
				{
					merchantState.merchant.mids &&
					merchantState.merchant.mids.filter((m: MerchantMid) => merchantState.merchant.mid === m.mid && m.recordType === MerchantRecordType.AFFILIATE).length &&
					formState.webPixelEnabled && formState.serverPixelEnabled &&
					renderOrderTrackingFieldSection()
				}
				{merchantState.merchant.imported !== undefined && !merchantState.merchant.imported && (
					<span>
						<hr/>
						<OnOffSwitcher
							value={productFeedSwitchState.value}
							isLoading={formState.isLoading}
							onChange={onProductFeedChange}
							mainText={getProductFeedMainText()}
							additionalText={
								<>
									<Text style={{fontSize: '12px', marginTop: '-1rem'}}>You can change this later if required</Text>
									<Text
										style={{fontSize: '12px', marginTop: '-1rem', cursor: 'pointer'}}
										className={!productFeedSwitchState.value || formState.isLoading ? 'text-gray-500 link-disabled' :
											'text-purple-500'}
									>
										<span
											onClick={() => {
												if (productFeedSwitchState.value) {
													getProductFeedFile();
												}
											}}
										>
											<Icon type='download' className={'mr-2'}/>
											Download product feed sample
										</span>
									</Text>
								</>
							}
						/>
					</span>
				)}
				{
					formState.networkId != 11 && (
						<span>
							<hr/>
							<Grid>
							<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
								<>
									<Icon
										type='checkmark-solid'
										className={ordersCancellationSwitchState.value ? 'w-6 h-8 text-green-500 mt-4' :
											'w-6 h-8 text-gray-500 mt-4'}
									/>
									<Text className={ordersCancellationSwitchState.value ? 'text-green-500 status-text' :
										'text-gray-500 status-text'}>
										Complete
									</Text>
								</>
							</GridCell>
							<GridCell key={2} width='7/12' className='mt-4 px-2' style={{margin: 'auto'}}>
								<Text variation='strong'>
									Automatically handle order cancellation
									<CustomPopover
										show={popoversState.autoCancellationShowPopover}
										className={'popover'}
										position={'right'}
										onShow={() => {
											setPopoversState({...popoversState, autoCancellationShowPopover: true});
										}}
										onDismiss={() => {
											setPopoversState({...popoversState, autoCancellationShowPopover: false});
										}}
										iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
										iconType={'information-solid'}
										actionsClassName={'popover-actions-grey'}
									>
										{getAutoCancellationPopoverText()}
									</CustomPopover>
								</Text>
								<Text style={{fontSize: '12px', marginTop: '-1rem'}}>You can change this later if required</Text>
							</GridCell>
							<GridCell key={3} width='1/4' className='text-center m-auto'>
						<span style={{display: 'flex', justifyContent: 'center'}}>
							<Text style={{marginBottom: 0, marginRight: '-1rem'}}>{ordersCancellationSwitchState.value ? 'On' :
								'Off'}</Text>
							<Switch
								isChecked={ordersCancellationSwitchState.value}
								value={ordersCancellationSwitchState.value}
								onChange={() => onOrderCancellationChange()}
								isDisabled={formState.isLoading}
							/>
						</span>
							</GridCell>
						</Grid>
						</span>
					)
				}
			</Main>
		);
	};

	const renderToDoElements = () => {
		return (
			<Main className='px-10 pt-4 mt-4' style={{minHeight: '10rem'}}>
				<Heading level={3}>Please complete the following steps (in this order)</Heading>
				<hr style={{marginTop: '7px'}}/>
				{merchantState.merchant.imported !== undefined && !merchantState.merchant.imported && (
					<span>
						<Grid>
							<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
								{getIcon(formState.thirdPartyAppStatus)}
							</GridCell>
							<GridCell key={2} width='7/12' className='mt-4 px-2'>
								<Text variation='strong'>Identify 3rd party apps</Text>
								<Text style={{ fontSize: '11px', marginTop: '-1rem' }}>
									Is your store using a 3rd party app for subscription or checkout?
								</Text>
							</GridCell>
							<GridCell key={3} width='1/4' className='text-center m-auto'>
								{extractThirdPartyAppButton()}
							</GridCell>
						</Grid>


						{
							formState.networkId != 11 && (
								<span>
									<hr/>
									<Grid>
							<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
								{getIcon(formState.ccpaStatus.status)}
							</GridCell>
							<GridCell key={2} width='7/12' className='mt-5 px-2'>
								<Text variation='strong'>
									Compliance
									<CustomPopover
										show={popoversState.complianceShowPopover}
										className={'popover'}
										position={'top'}
										onShow={() => {
											setPopoversState({ ...popoversState, complianceShowPopover: true });
										}}
										onDismiss={() => {
											setPopoversState({ ...popoversState, complianceShowPopover: false });
										}}
										iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
										iconType={'information-solid'}
										actionsClassName={'popover-actions-grey'}
									>
										{
											<p>
												This step takes care of CCPA compliance only. For GDPR compliance, please work directly with your Rakuten
												representative.
											</p>
										}
									</CustomPopover>
								</Text>
								<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Does your store comply with CCPA regulations?</Text>
								{formState.ccpaStatus.status.valueOf() === StepStatus.InReview.valueOf() &&
									formState.ccpaStatus.withGuide &&
									getGuideLink(formState.ccpaStatus.type)}
							</GridCell>
							<GridCell key={3} width='1/4' className='text-center m-auto'>
								{extractCCPAButton()}
							</GridCell>
						</Grid>
								</span>
							)}

						<hr />
					</span>
				)}

				{!formState.webPixelEnabled && (
					<Grid>
						<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
							{getIcon(formState.additionalScriptStatus)}
						</GridCell>
						<GridCell key={2} width='7/12' className='mt-5 px-2'>
							<Text variation='strong'>
								Additional script<span style={{ fontWeight: 300 }}> (optional but recommended)</span>
							</Text>
							<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Allows you to track more data for commissioning and reporting</Text>
						</GridCell>
						<GridCell key={3} width='1/4' className='text-center m-auto'>
							{extractAdditionalButton()}
						</GridCell>
					</Grid>
				)}

				<hr />

				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.pixelTrackingStatus)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-4 px-2'>
						<Text variation='strong'>Pixel tracking test</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Ensures your tracking is working correctly</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractPixelTrackingButton()}
					</GridCell>
				</Grid>
			</Main>
		);
	};

	const renderNonAffiliateToDoElements = () => {
		return (
			<Main className='px-10 pt-4 mt-4' style={{ minHeight: '10rem' }}>
				<Heading level={3}>Please complete the following steps (in this order)</Heading>
				<hr style={{ marginTop: '7px' }} />
				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.thirdPartyAppStatus)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-4 px-2'>
						<Text variation='strong'>Identify 3rd party apps</Text>
						<Text style={{ fontSize: '11px', marginTop: '-1rem' }}>Is your store using a 3rd party app for subscription or checkout?</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractThirdPartyAppButton()}
					</GridCell>
				</Grid>

				<hr />

				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.ccpaStatus.status)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-5 px-2'>
						<Text variation='strong'>
							Compliance
							<CustomPopover
								show={popoversState.complianceShowPopover}
								className={'popover'}
								position={'top'}
								onShow={() => {
									setPopoversState({ ...popoversState, complianceShowPopover: true });
								}}
								onDismiss={() => {
									setPopoversState({ ...popoversState, complianceShowPopover: false });
								}}
								iconClassName={'text-purple-500 ml-2 w-5 h-8 icon-tooltip'}
								iconType={'information-solid'}
								actionsClassName={'popover-actions-grey'}
							>
								<p>
									This step takes care of CCPA compliance only. For GDPR compliance, please work directly with your Rakuten representative.
								</p>
							</CustomPopover>
						</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Does your store comply with CCPA regulations?</Text>
						{formState.ccpaStatus.status.valueOf() === StepStatus.InReview.valueOf() &&
							formState.ccpaStatus.withGuide &&
							getGuideLink(formState.ccpaStatus.type)}
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractCCPAButton()}
					</GridCell>
				</Grid>

				<hr />

				<Grid>
					<GridCell key={1} width='1/6' className='text-center m-auto px-2'>
						{getIcon(formState.additionalScriptStatus)}
					</GridCell>
					<GridCell key={2} width='7/12' className='mt-5 px-2'>
						<Text variation='strong'>
							Additional script<span style={{ fontWeight: 300 }}> (optional but recommended)</span>
						</Text>
						<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Allows you to track more data for commissioning and reporting</Text>
					</GridCell>
					<GridCell key={3} width='1/4' className='text-center m-auto'>
						{extractAdditionalButton()}
					</GridCell>
				</Grid>
			</Main>
		);
	};

	useEffect(() => {
		if (merchant) {
			setMerchantState({ merchant: merchant });
			getStatuses(merchant.mid);
		}
		if (formState.isStatusLoaded) {
			setFormState({ ...formState, isStatusLoaded: false });
		}
	}, [merchant]);

	useEffect(() => {
		if (statuses) {
			setFormState({ ...statuses });
			setProductFeedSwitchState({ value: statuses.productFeedSyncStatus === StepStatus.Completed });
			setOrdersCancellationSwitchState({ value: statuses.orderCancellationStatus === StepStatus.Completed });
			setOrderTrackingFieldState({ value: statuses.orderTrackingField, showConfirmation: false, errorMessage: '' });
		}
	}, [statuses]);

	useEffect(() => {
		return () => {
			dispatch(HomeAPI.clearState());
		};
	}, []);

	const renderContent = () => {
		if (
			merchantState.merchant.mids &&
			merchantState.merchant.mids.filter((m: MerchantMid) => m.recordType === MerchantRecordType.AFFILIATE).length ===
				merchantState.merchant.mids.length
		) {
			return (
				<>
					{renderAutomaticallyCompletedElements()}
					{renderToDoElements()}
				</>
			);
		}

		if (
			merchantState.merchant.mids &&
			merchantState.merchant.mids.filter((m: MerchantMid) => m.recordType !== MerchantRecordType.AFFILIATE).length ===
				merchantState.merchant.mids.length
		) {
			return (
				<>
					<Main className='px-10 pt-4' style={{ minHeight: '10rem' }}>
						<Heading level={3}>Shopify app installation</Heading>
						<hr style={{ marginTop: '7px' }} />
						<Grid>
							<GridCell className='text-center m-auto px-2' key={1} width='1/6'>
								{getIcon(formState.spiStatus)}
							</GridCell>
							<GridCell key={2} width='5/6' className='mt-5 px-2'>
								<Text variation='strong'>Tracking tags install</Text>
								<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Basic tracking for your store</Text>
							</GridCell>
						</Grid>
					</Main>
					{renderNonAffiliateToDoElements()}
				</>
			);
		}

		const isNonAffiliateSelected =
			merchantState.merchant.mids &&
			merchantState.merchant.mids.filter(
				(m: MerchantMid) => m.mid === merchantState.merchant.mid && m.recordType === MerchantRecordType.AFFILIATE
			).length === 0;
		if (isNonAffiliateSelected) {
			return (
				<>
					<Main className='px-10 pt-4' style={{ minHeight: '10rem' }}>
						<Heading level={3}>Shopify app installation</Heading>
						<hr style={{ marginTop: '7px' }} />
						<Grid>
							<GridCell className='text-center m-auto px-2' key={1} width='1/6'>
								{getIcon(formState.spiStatus)}
							</GridCell>
							<GridCell key={2} width='5/6' className='mt-5 px-2'>
								<Text variation='strong'>Tracking tags install</Text>
								<Text style={{ fontSize: '12px', marginTop: '-1rem' }}>Basic tracking for your store</Text>
							</GridCell>
						</Grid>
					</Main>
				</>
			);
		} else {
			return (
				<>
					{renderAutomaticallyCompletedElements()}
					{renderToDoElements()}
				</>
			);
		}
	};

	if (!formState.isStatusLoaded) {
		return <Spinner />;
	}

	return (
		<>
			{renderContent()}
			<OrderTrackingFieldConfirmation
				open={orderTrackingFieldState.showConfirmation}
				closeHandler={() => closeOrderTrackingFieldConfirmation()}
				errorMessage={orderTrackingFieldState.errorMessage ? `Update error: ${orderTrackingFieldState.errorMessage}` : ''}
				headerText={`Please confirm that you want to update the Order ID Type to "${OrderTrackingField[formState.orderTrackingField!]}" and use it for transaction tracking going forward.`}
				confirmButtonText='Update'
				handleConfirm={updateOrderTrackingField}
			/>
			<Main className='px-10 pt-4' style={{ backgroundColor: 'transparent', display: 'table' }}>
				<a style={{ fontSize: 12, color: '#7141d8' }} rel='noopener noreferrer' href='https://dashboard.linkshare.com/' target='_blank'>
					Go to the Rakuten Advertiser Dashboard to continue onboarding steps
				</a>
			</Main>
		</>
	);
};

export default Home;
